<template>
  <Layout :top-bar-title="title" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <ChainsForm
      :showHeader="false"
      @close="$router.push({name: 'ChainsList'})"
      />
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import sections from '../../dido/views/defaultNavigationDrawerSections'
import { mapActions, mapState } from 'vuex'
import ChainsForm from '../components/forms/distributor/ChainsForm'

export default {
  components: { ChainsForm, Layout },

  data () {
    return {
      title: 'Cadena',
      sections: sections,
      distributorId: this.$route.params.id
    }
  },
  mounted () {
    if (this.distributorId === 'new') {
      this.getInitialTemplate()
    } else {
      this.getItem(this.distributorId)
    }
  },
  methods: {
    ...mapActions('chainsCatalog', ['getItem', 'getInitialTemplate'])
  },
  computed: {
    ...mapState('chainsCatalog', ['currentItem'])
  }
}
</script>
